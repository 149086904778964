import React, { useRef, useState } from 'react'
import { useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import {Link, navigate} from 'gatsby';
import './LoginForm.css';
import {
    LOGIN_HEADER,
    LOGIN_EMAIL,
    LOGIN_PASSWORD,
    FORGOT_PASSWORD
} from 'utils/messages'
import { authErrors } from 'store/selectors/selectors';
import Loader from 'components/global/Loader/Loader';
import FormInput from 'components/formFields/FormInput';
import {ButtonLabels, ButtonSizes, ButtonTypes, FetchMethods} from 'utils/constants';
import fetchApi from 'common/fetchApi';
import {BEAM_LOGIN, GET_USER_INFO} from 'common/endpoints';
import { ErrorMessage } from 'components/global/ErrorMessage/ErrorMessage';
import {FormPassword} from "components/formFields/FormPassword/FormPassword";
import {Button} from "components/buttons/Button/Button";
import ReCAPTCHA from 'react-google-recaptcha'

export const LoginForm = () => {
    const { handleSubmit, register, setValue } = useForm();
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(false);
    const [showError, setShowError] = useState(false);
    const captchaRef = useRef(null)
    const [ showRecaptcha, setShowRecaptcha ] = useState()
    const [ errors, setErrors ] = useState([]);
    const [ errorMessage, setErrorMessage ] = useState()

    const onSubmit = async data => {
        setIsLoading(true);
        try {
            const authResponse = await fetchApi(BEAM_LOGIN, {
                method: FetchMethods.Post,
                data: { email: data.email, password: data.password, recaptcha: data.recaptcha }
            });

            setShowError(false);

            dispatch.auth.setLoggedIn({...authResponse.data, email: data.email});
            const userInfoResponse = await fetchApi(GET_USER_INFO);
            dispatch.auth.setUserInfo({...userInfoResponse.data});


            captchaRef?.current?.reset();
            setIsLoading(false);
            await navigate('/app/dashboard/');
        } catch (error) {
            const errors = error.response?.data;
            setErrors(errors?.errors[0]);
            setErrorMessage(errors?.message)

            if (errors?.showRecaptcha) {
                setShowRecaptcha(!!errors?.showRecaptcha)
            }

            setShowError(true);
            captchaRef?.current?.reset();
            setIsLoading(false);
        }
    }

    if (isLoading) {
        return (
            <div className='login-loader-container'>
                <Loader/>
            </div>
        );
    }

    return (
        <form className={`login-form ${showRecaptcha && 'form-with-recaptcha'}`}>
            <div className='login-header'>{LOGIN_HEADER}</div>
            {showError && <ErrorMessage message={errorMessage} />}
            <FormInput
                type='email'
                label={LOGIN_EMAIL}
                register={register}
                isRequired
                error={errors?.email}
                fieldName='email'
                errorsSelector={authErrors}
            />
            <div className='login-password'>
                <FormPassword
                    label={LOGIN_PASSWORD}
                    fieldName='password'
                    register={register}
                    error={errors?.password}
                    dataTestId='input-password'
                />
                {showRecaptcha &&
                <ReCAPTCHA
                    ref={captchaRef}
                    sitekey={process.env.GATSBY_RECAPTCHA_PUBLIC_KEY}
                    className='reset-password-recaptcha'
                    onChange={value => {
                        setValue('recaptcha', value)
                    }}
                />
                }
                <Link
                    to='/app/reset-password'
                    data-testid='login-forgot-password'
                    className='login-forgot-password-link'
                >
                    {FORGOT_PASSWORD}
                </Link>
            </div>
            <div className='login-submit-button'>
                <Button
                    handleOnClick={handleSubmit(onSubmit)}
                    label={ButtonLabels.LogIn}
                    type={ButtonTypes.Primary}
                    size={ButtonSizes.Large}
                    dataTestId='button-step-next'
                />
            </div>
        </form>
    )
};
