import React from 'react'
import { LoginForm } from 'components/forms/LoginForm/LoginForm';
import { useDispatch } from "react-redux";
import { navigate } from "gatsby";
import LoginPage from "components/container/LoginPage/LoginPage";

const Login = () => {

    const dispatch = useDispatch();

    const isBrowser = typeof window !== "undefined";
    const cachedAuthState = isBrowser && window.sessionStorage.getItem('cachedAuthState');

    if (cachedAuthState)
    {
        dispatch.auth.setAuthState(cachedAuthState)

        const path = JSON.parse(cachedAuthState)?.location;

        path && path !== '/app/legacy-admin' ?
            navigate(path)
            : navigate('/app/dashboard')
    }

    return (
        <>
            {!cachedAuthState && <LoginPage Form={LoginForm}/>}
        </>
    )
}
export default Login;
